<template>
  <div>
    <div
      v-if="type == 'basic' || type == 'community'"
      class="w-60 h-[336px] rounded-2xl flex flex-col gap-4 p-2"
      :class="{
        'w-[184px] h-[258px] !p-2': formatSm,
        'border-secondary border-4': enableSelection && selected,
        'border-white border-4': enableSelection && !selected,
        'bg-[#050931] text-white': type == 'basic',
        'bg-[#dedef6]': type == 'community',
      }"
    >
      <div v-if="badgeImage" class="w-full aspect-square overflow-hidden">
        <img
          :src="badgeImage"
          alt="badgePicture"
          class="object-cover rounded-lg overflow-hidden h-full w-full"
        />
      </div>
      <div
        v-else
        class="w-full border-gray-400 border rounded-xl aspect-square overflow-hidden"
      ></div>
      <div class="mb-2">
        <!-- <p
          class="font-normal text-left leading-tight line-clamp-1 break-all"
          :class="!formatSm ? 'text-xs' : 'text-xxs'"
        >
          {{ issuerName }}
        </p> -->
        <p
          class="font-bold text-left leading-tight line-clamp-2 mb-1.5"
          :class="!formatSm ? 'text-md' : 'text-xs'"
        >
          {{ badgeName }}
        </p>
        <p
          class="font-light text-left leading-tight line-clamp-2 break-all"
          :class="!formatSm ? 'text-sm' : 'text-xxs'"
        >
          {{ templateLabel }}
        </p>
      </div>
    </div>
    <div
      v-if="type == 'participation'"
      class="w-60 h-[336px] border bg-white rounded-2xl relative flex flex-col overflow-hidden"
      :class="{
        'w-[184px] h-[258px]': formatSm,
        'border-secondary border-4': enableSelection && selected,
        'border-gray-200 border-4': enableSelection && !selected,
      }"
    >
      <img
        :src="require('/assets/images/attendanceProofPreviewTopBanner.png')"
        class="absolute w-full"
      />
      <div class="flex justify-center relative mt-4 mb-3">
        <img
          v-if="badgeImage"
          :src="badgeImage"
          alt="badgePicture"
          class="w-32 h-32 rounded-full border-2"
        />
        <div
          v-else
          class="w-32 h-32 shadow-inner bg-gray-light rounded-full border-2"
        ></div>
      </div>
      <p
        class="text-center leading-5 line-clamp-1 font-normal break-all"
        :class="!formatSm ? '' : 'text-xs'"
      >
        {{ issuerName }}
      </p>
      <p
        class="text-center line-clamp-1 font-bold break-all"
        :class="!formatSm ? '' : 'text-sm'"
      >
        {{ badgeName }}
      </p>
      <p
        class="text-center line-clamp-1 break-all mb-3"
        :class="!formatSm ? 'text-sm' : 'text-xs'"
      >
        {{ templateLabel }}
      </p>
      <div class="grow"></div>
      <div v-if="!formatSm" class="flex p-3">
        <div>
          <p class="bg-gray-light text-xs px-1 py-0.5 rounded-md">
            {{ badgeStartDate ? badgeStartDate.split('T')[0] : '.. /.. /..' }}
          </p>
          <p
            v-if="
              (badgeStartDate &&
                badgeEndDate &&
                badgeStartDate.split('T')[0] != badgeEndDate.split('T')[0]) ||
              !badgeStartDate ||
              !badgeEndDate
            "
            class="bg-gray-light text-xs px-1 py-0.5 rounded-md"
          >
            {{ badgeEndDate ? badgeEndDate.split('T')[0] : '.. /.. /..' }}
          </p>
        </div>
        <div class="grow"></div>
        <div class="flex items-end">
          <span class="text-xs bg-gray-light px-1 py-0.5 rounded-md">{{
            badgeLocation ? badgeLocation : '...'
          }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="type == 'membership'"
      class="w-80 rounded-2xl relative flex items-center"
      :class="{
        'border-secondary border-4': enableSelection && selected,
        'border-transparent border-4': enableSelection && !selected,
        '!w-96 !rounded-2xl': formatXl,
      }"
    >
      <div
        v-if="badgeImage"
        class="w-full aspect-[85.60/53.98] rounded-xl flex justify-center items-center overflow-hidden"
      >
        <img
          :src="badgeImage"
          alt="badgePicture"
          class="object-cover min-h-full min-w-full"
        />
      </div>
      <div
        v-else
        class="w-full aspect-[85.60/53.98] bg-gray-light rounded-lg"
        :class="{
          '!w-96 !h-[245px]': formatXl,
          '!rounded-2xl': formatXl,
        }"
      ></div>
      <div
        class="absolute h-10 left-0 top-0 p-2"
        :class="{
          '!h-12': formatXl,
        }"
      >
        <div
          class="backdrop-blur-sm h-full inline-flex bg-[#050931] bg-opacity-40 rounded-md items-center px-2 py-1"
          :class="{
            'px-3 py-2': formatXl,
          }"
        >
          <p
            class="text-white font-medium text-xs"
            :class="{
              '!text-sm': formatXl,
            }"
          >
            {{ membershipIssuerDisplayedName }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
export default {
  props: {
    badgeImage: String,
    badgeName: String,
    badgeStartDate: String,
    badgeEndDate: String,
    badgeLocation: String,
    issuerName: String,
    type: String,
    formatSm: Boolean,
    formatXl: Boolean,
    enableSelection: Boolean,
    selected: Boolean,
  },
  setup(props) {
    const templateLabel = ref(templateTypeToLabel(props.type));

    function templateTypeToLabel(type) {
      switch (type) {
        case 'basic':
          return 'Certified badge';
        case 'community':
          return 'Community badge';
        case 'participation':
          return 'Participation badge';
        case 'membership':
          return 'Membership card';
      }
    }

    const membershipIssuerDisplayedName = computed(() => {
      return props.issuerName.length >= 20
        ? props.issuerName.substring(0, 17) + '...'
        : props.issuerName;
    });
    return { templateLabel, membershipIssuerDisplayedName };
  },
};
</script>
