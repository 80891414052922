<template>
  <div class="bg-white px-5 py-4 border 2xl:rounded-xl 2xl:shadow-md mx-auto">
    <div class="flex items-center">
      <div
        v-if="verifiableCredential.type != 'original'"
        class="flex items-center"
      >
        <icon
          :name="`badge-${verifiableCredential.type.toLowerCase()}`"
          class="ml-2 my-2 mr-2"
        />
        <p class="text-lg font-bold my-2">
          {{ templateLabel }}
        </p>
      </div>
      <p v-else class="text-lg text-gray-500 mx-2 my-2">
        <span class="relative mr-8"
          ><div
            class="absolute w-5 h-5 inline-block border-2 border-gray-500 rounded-full mt-1"
          ></div
        ></span>
        {{ verifiableCredential.key }}
      </p>
      <div
        v-if="
          verifiableCredential.expirationDate &&
          new Date(verifiableCredential.expirationDate) < new Date()
        "
        class="ml-auto"
      >
        <p
          class="bg-red-100 border-red-300 text-red-500 border pl-1.5 pr-2 py-0.5 rounded-xl"
        >
          <icon
            name="triangle-exclamation"
            class="fill-red-500 mr-1.5"
          />Expired
        </p>
      </div>
      <div v-else class="ml-auto">
        <p
          class="bg-green-100 border-green-300 text-green-500 border pl-1.5 pr-2 py-0.5 rounded-xl"
        >
          <icon name="wavy-check" class="fill-green-500 mr-1.5" />Valid
        </p>
      </div>
    </div>
    <div class="2xl:flex gap-10 p-3 2xl:p-10">
      <div
        v-if="
          verifiableCredential.type != 'original' &&
          verifiableCredential.type != 'role'
        "
      >
        <TemplatePreview
          :badgeImage="verifiableCredential.image"
          :badgeName="verifiableCredential.name"
          :badgeLocation="
            verifiableCredential.type == 'participation'
              ? verifiableCredential.location.split(', ')[0]
                ? verifiableCredential.location.split(', ')[0]
                : 'Virtual'
              : null
          "
          :badgeStartDate="
            verifiableCredential.type == 'participation'
              ? verifiableCredential.startDate
              : null
          "
          :badgeEndDate="
            verifiableCredential.type == 'participation'
              ? verifiableCredential.endDate
              : null
          "
          :issuerName="verifiableCredential.issuer.name"
          :formatSm="true"
          :type="verifiableCredential.type.toLowerCase()"
          class="flex-none 2xl:hidden"
        />
        <TemplatePreview
          :badgeImage="verifiableCredential.image"
          :badgeName="verifiableCredential.name"
          :badgeLocation="
            verifiableCredential.type == 'participation'
              ? verifiableCredential.location.split(', ')[0]
                ? verifiableCredential.location.split(', ')[0]
                : 'Virtual'
              : null
          "
          :badgeStartDate="
            verifiableCredential.type == 'participation'
              ? verifiableCredential.startDate
              : null
          "
          :badgeEndDate="
            verifiableCredential.type == 'participation'
              ? verifiableCredential.endDate
              : null
          "
          :issuerName="verifiableCredential.issuer.name"
          :formatXl="verifiableCredential.type != 'membership' ? true : false"
          :type="verifiableCredential.type.toLowerCase()"
          class="flex-none hidden 2xl:block"
        />
      </div>

      <div v-else>
        <div
          class="w-[150px] h-[150px] 2xl:w-[300px] 2xl:h-[300px] flex items-center justify-center relative overflow-hidden"
        >
          <div
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <img
              :src="require('/assets/images/role_background_image.png')"
              class="w-80 max-w-none"
            />
          </div>
          <TemplateRolePreview
            v-if="verifiableCredential.type == 'role'"
            :name="verifiableCredential.name"
            :emoji="verifiableCredential.image"
            :color="verifiableCredential.color"
            class="z-50"
          ></TemplateRolePreview>
          <img
            v-else
            :src="require(`/assets/images/vc_${verifiableCredential.key}.png`)"
            class="w-20 max-w-none z-50"
          />
        </div>
      </div>
      <div class="grow">
        <div v-if="verifiableCredential.type == 'original'">
          <p class="text-lg 2xl:text-2xl font-bold mt-3 mb-7">
            {{ verifiableCredential.value }}
          </p>
        </div>
        <div
          v-if="
            verifiableCredential.type == 'basic' ||
            verifiableCredential.type == 'community'
          "
        >
          <p class="text-lg 2xl:text-2xl font-bold mt-3 mb-7">
            {{ verifiableCredential.name }}
          </p>
          <div class="2xl:flex mb-5">
            <p class="flex-none w-40 text-gray-400">
              <icon
                name="text-align-left"
                class="fill-gray-400 mr-1.5"
              />Description
            </p>
            <p class="grow">{{ verifiableCredential.description }}</p>
          </div>
          <div class="2xl:flex mb-5">
            <p class="flex-none w-40 text-gray-400">
              <icon name="checklist" class="fill-gray-400 mr-1.5" />Criteria
            </p>
            <p class="grow">{{ verifiableCredential.criteria }}</p>
          </div>
        </div>
        <div v-else-if="verifiableCredential.type == 'participation'">
          <p class="text-lg 2xl:text-2xl font-bold mt-3 mb-7">
            {{ verifiableCredential.name }}
          </p>
          <div class="2xl:flex mb-5">
            <p class="flex-none w-40 text-gray-400">
              <icon
                name="text-align-left"
                class="fill-gray-400 mr-1.5"
              />Description
            </p>
            <p class="grow">{{ verifiableCredential.description }}</p>
          </div>
        </div>
        <div v-else-if="verifiableCredential.type == 'membership'">
          <p class="text-lg 2xl:text-2xl font-bold mt-3 mb-7">
            Membership card
          </p>
          <div class="2xl:flex mb-5">
            <p class="flex-none w-40 text-gray-400">
              <icon
                name="text-align-left"
                class="fill-gray-400 mr-1.5"
              />Description
            </p>
            <p class="grow">{{ verifiableCredential.description }}</p>
          </div>
        </div>
        <div v-else-if="verifiableCredential.type == 'role'">
          <p class="text-lg 2xl:text-2xl font-bold mt-3 mb-7">
            {{ verifiableCredential.name }}
          </p>
          <div class="2xl:flex mb-5">
            <p class="flex-none w-40 text-gray-400">
              <icon
                name="text-align-left"
                class="fill-gray-400 mr-1.5"
              />Description
            </p>
            <p class="grow">{{ verifiableCredential.description }}</p>
          </div>
        </div>
        <div
          v-if="verifiableCredential.type != 'original'"
          class="border-b mb-5"
        ></div>
        <div>
          <div class="2xl:flex mb-5">
            <div class="flex-none w-40 text-gray-400 break-words">
              <icon name="user-square" class="fill-gray-400 mr-1.5" />Owner
            </div>
            <div class="grow">
              <p class="break-all">{{ verifiableCredential.owner }}</p>
            </div>
          </div>
          <div v-if="vc.timestamp" class="2xl:flex 2xl:items-center mb-5">
            <div class="flex-none w-40 text-gray-400">
              <icon name="calendar-add" class="fill-gray-400 mr-1.5" />Created
              on
            </div>
            <div class="">
              {{
                new Date(verifiableCredential.created).toLocaleDateString() +
                ' ' +
                new Date(vc.timestamp * 1000)
                  .toISOString()
                  .split('T')[1]
                  .split('.')[0] +
                ' (UTC+0)'
              }}
            </div>
            <div
              v-if="
                Math.abs(
                  new Date(vc.timestamp * 1000) -
                    new Date(verifiableCredential.created)
                ) /
                  1000 <
                120
              "
              class="2xl:ml-3"
            >
              <span
                class="bg-green-100 border-green-300 text-green-500 border pl-1.5 pr-2 py-0.5 rounded-xl"
              >
                <icon
                  name="wavy-check"
                  class="fill-green-500 mr-1.5"
                />Blockchain certified</span
              >
            </div>
            <div v-else class="2xl:ml-3">
              <span
                class="bg-red-100 border-red-300 text-red-500 border pl-1.5 pr-2 py-0.5 rounded-xl"
              >
                <icon
                  name="triangle-exclamation"
                  class="fill-red-500 mr-1.5"
                />Blockchain warning</span
              >
            </div>
          </div>
          <div v-else class="2xl:flex mb-5">
            <div class="flex-none w-40 text-gray-400">
              <icon name="calendar-add" class="fill-gray-400 mr-1.5" />Created
              on
            </div>
            <div class="grow">
              {{ new Date(verifiableCredential.created).toLocaleDateString() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="2xl:flex bg-gray-light rounded-xl 2xl:mt-10 p-3">
      <div class="grow">
        <p
          class="bg-white border-2 inline-flex rounded-xl font-medium px-1.5 py-0.5 mb-2"
        >
          <icon name="user-card-id" class="mt-1 mr-1.5" />Sender
        </p>

        <div class="2xl:flex p-4">
          <div class="flex-none w-48">
            <p class="text-gray-500">
              <icon
                name="user-square"
                class="fill-gray-500 mt-1 mr-1.5"
              />Identifier
            </p>
          </div>
          <div class="grow">
            <p class="break-all">{{ verifiableCredential.issuer.did }}</p>
          </div>
        </div>
        <div v-if="verifiableCredential.endorsement" class="2xl:flex p-4">
          <div class="flex-none w-48">
            <p class="text-gray-500">
              <icon name="wavy-check" class="fill-gray-500 mt-1 mr-1.5" />Role
            </p>
          </div>
          <div class="grow">
            <TemplateRolePreview
              v-if="verifiableCredential.endorsement.type == 'role'"
              :name="verifiableCredential.endorsement.name"
              :emoji="verifiableCredential.endorsement.image"
              :color="verifiableCredential.endorsement.color"
              class="z-50"
            ></TemplateRolePreview>
          </div>
        </div>
        <div
          v-if="
            verifiableCredential.issuer.type != 'DID' &&
            verifiableCredential.issuer.website
          "
          class="2xl:flex p-4"
        >
          <div class="flex-none w-48">
            <p class="text-gray-500">
              <icon name="website" class="fill-gray-500 mt-1 mr-1.5" />Website
            </p>
          </div>
          <div class="grow">
            <p>{{ verifiableCredential.issuer.website }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="verifiableCredential.issuer.type != 'DID'"
        class="flex-none bg-white border shadow-blue-300 mt-auto py-2 px-4 rounded-3xl"
        style="box-shadow: inset 0 0 4px 2px rgba(4, 84, 255, 0.3)"
      >
        <div class="flex items-center">
          <img
            :src="
              verifiableCredential.issuer.type == 'Issuer Profile'
                ? verifiableCredential.issuer.profileImage.replace(
                    'https://ipfs.infura.io/ipfs/',
                    'https://myntfsid.mypinata.cloud/ipfs/'
                  )
                : verifiableCredential.endorsement
                ? verifiableCredential.endorsement.image
                : require('/assets/images/user.png')
            "
            alt=""
            class="rounded-full border w-12 h-12 mr-5"
          />
          <p class="text-lg font-medium">
            {{ verifiableCredential.issuer.name }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="verifiableCredential.endorsement"
      class="2xl:flex bg-gray-light rounded-xl mt-5 p-3"
    >
      <div class="grow">
        <p
          class="bg-white border-2 inline-flex rounded-xl font-medium px-1.5 py-0.5 mb-2"
        >
          <icon name="user-group" class="mt-1 mr-1.5" />Community
        </p>

        <div class="2xl:flex p-4">
          <div class="flex-none w-48">
            <p class="text-gray-500">
              <icon
                name="user-square"
                class="fill-gray-500 mt-1 mr-1.5"
              />Identifier
            </p>
          </div>
          <div class="grow">
            <p class="break-all">
              {{ verifiableCredential.endorsement.issuer.did }}
            </p>
          </div>
        </div>
        <div
          v-if="
            verifiableCredential.endorsement.issuer.type != 'DID' &&
            verifiableCredential.issuer.website
          "
          class="2xl:flex p-4"
        >
          <div class="flex-none w-48">
            <p class="text-gray-500">
              <icon name="website" class="fill-gray-500 mt-1 mr-1.5" />Website
            </p>
          </div>
          <div class="grow">
            <p>{{ verifiableCredential.endorsement.issuer.website }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="verifiableCredential.endorsement.issuer.type != 'DID'"
        class="flex-none bg-white border shadow-blue-300 mt-auto py-2 px-4 rounded-3xl"
        style="box-shadow: inset 0 0 4px 2px rgba(4, 84, 255, 0.3)"
      >
        <div class="flex items-center">
          <img
            :src="
              verifiableCredential.endorsement.issuer.type == 'Issuer Profile'
                ? verifiableCredential.endorsement.issuer.profileImage.replace(
                    'https://ipfs.infura.io/ipfs/',
                    'https://myntfsid.mypinata.cloud/ipfs/'
                  )
                : verifiableCredential.endorsement.endorsement
                ? verifiableCredential.endorsement.endorsement.image
                : require('/assets/images/user.png')
            "
            alt=""
            class="rounded-full border w-12 h-12 mr-5"
          />
          <p class="text-lg font-medium">
            {{ verifiableCredential.endorsement.issuer.name }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="showDynamicQrCode">
      <div
        @click="showDynamicQrCode = false"
        class="w-full h-full fixed top-0 left-0 z-40 bg-black bg-opacity-50"
      ></div>
      <div
        class="overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 rounded-lg shadow-lg"
      >
        <img v-if="dynamicQrCodeSrc" :src="dynamicQrCodeSrc" />
        <div v-else class="flex justify-center">
          <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, computed } from 'vue';
import { createGif } from '../plugins/qrGif';
import TemplatePreview from './TemplatePreview.vue';
import TemplateRolePreview from './TemplateRolePreview.vue';
export default {
  props: {
    vc: Object,
  },
  setup(props) {
    const verifiableCredential = ref(props.vc);
    const dynamicQrCodeSrc = ref(null);
    const showDynamicQrCode = ref(false);
    const templateLabel = ref(
      templateTypeToLabel(verifiableCredential.value.type)
    );

    function templateTypeToLabel(type) {
      switch (type) {
        case 'basic':
          return 'Certified badge';
        case 'community':
          return 'Community badge';
        case 'participation':
          return 'Participation badge';
        case 'membership':
          return 'Membership card';
        case 'role':
          return 'Role badge';
      }
    }

    watch(showDynamicQrCode, () => {
      if (showDynamicQrCode) {
        createGif(JSON.stringify(verifiableCredential.value.raw)).then(
          (res) => {
            dynamicQrCodeSrc.value = res;
          }
        );
      }
    });

    return {
      verifiableCredential,
      showDynamicQrCode,
      dynamicQrCodeSrc,
      templateLabel,
    };
  },
  components: {
    TemplatePreview,
    TemplateRolePreview,
  },
};
</script>
